@import '../customer-cp.module.scss';

.passwordRow {
    display: grid;
    grid-template-columns: 120px 1fr max-content;
    gap: 0px;

    @media (max-width: 400px) {
        gap: 15px;
        font-size: 12px;
        .pswButton {
            font-size: 12px;
        }
    }
}

.shopAccountStyles{
    display: flex;
    flex-direction: column;
    column-gap: 10px;
    p{
        color: gray;
    }
}

.accountRow {
    display: grid;
    grid-template-columns: 200px 1fr;

    @media (max-width: 400px) {
        font-size: 12px;
    }
}
.email{
    margin-top: 0px;
    flex: 0.8;

    input[type="text"]{
        width: 100%;
        border-bottom: 1px solid black;
    }

    a{
        color: gray;
        &:hover{
            color:rgb(160, 160, 160)
        }

        display: inline
    }
}

.tagView{
    margin-top: 0px;
    flex: 0.8;
    display:flex;
    flex-direction: row;
    align-content: center;

    input[type="text"]{
        width: 100%;
        border-bottom: 1px solid black;
    }
    .copy {
        margin-top: 2px;
        margin-left: 5px;
    }

    a{
        color: gray;
        &:hover{
            color:rgb(160, 160, 160)
        }

        display: inline;

        .icon {
            font-size: 18px;
        }
    }
}

.error {
    color: red;
    font-size: 0.875rem;
    margin-top: 0.5rem;
}

.password {
    margin-top: 2px;
}
